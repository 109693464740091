import { Utils } from 'prosumer-app/core/utils/utils';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { of, Observable } from 'rxjs';

@Injectable()
export class SharedLandingPageResolver {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const { projectId, token } = Utils.resolveToEmptyObject(route.queryParams);

    if (!token || !projectId) {
      this.router.navigateByUrl('dashboard');
    }

    return of({ projectId, token });
  }
}
