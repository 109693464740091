import {
  TRAINING_URL_REDIRECT_PROVIDER,
  UPDATES_URL_REDIRECT_PROVIDER,
} from 'prosumer-app/app.token';
import { AuthGuard } from 'prosumer-app/core/guards';
import {
  FAQ_URL_REDIRECT_PROVIDER,
  HOME_URL_REDIRECT_PROVIDER,
  LOGIN_URL_REDIRECT_PROVIDER,
} from 'prosumer-app/libs/eyes-core';
import { Features } from 'prosumer-app/libs/eyes-shared';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorPageComponent } from './+error';
import { SharedLandingPageResolver } from './+share/components/shared-landing-page/shared-landing-page-resolver.service';
import { UserInfoResolver } from './core/guards/user-info';

export const APP_NAME = 'Prosumer';
export const APP_SEPARATOR = ' | ';
export const TITLE_SUFFIX = APP_SEPARATOR + APP_NAME;
const loadErrorModule = import('./+error/error-page.module').then(
  (m) => m.ErrorPageModule,
);
export const FEATURES: Features = {
  login: {
    name: 'Login',
    path: 'login',
  },
  home: {
    name: 'Home',
    path: 'home',
    preload: true,
    title: 'Home' + TITLE_SUFFIX,
    toolbarLink: false,
    icon: 'home',
  },
  dashboard: {
    name: 'Home',
    path: 'dashboard',
    preload: true,
    title: 'Dashboard' + TITLE_SUFFIX,
    breadcrumb: 'Dashboard',
    toolbarLink: false,
    sideNav: true,
    sideNavLink: true,
    icon: 'home',
  },
  projects: {
    name: 'Projects',
    path: 'projects',
    title: 'Projects' + TITLE_SUFFIX,
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Projects',
    icon: 'exit_to_app',
  },
  faq: {
    name: 'FAQ',
    path: 'faq',
    title: 'FAQ' + TITLE_SUFFIX,
    icon: 'question_answer',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  renewable_profile: {
    name: 'Renewable Profile',
    path: 'renewable_profile',
    preload: true,
    title: 'Renewable Profile',
    sideNavLink: true,
    sideNav: true,
    breadcrumb: 'Renewable Profile',
    icon: 'svg_renewable_profile',
  },
  training: {
    name: 'Training',
    path: 'training',
    title: 'Training' + TITLE_SUFFIX,
    sideNavLink: true,
    icon: 'star',
  },
  updates: {
    name: 'Updates',
    path: 'updates',
    title: 'Updates' + TITLE_SUFFIX,
    sideNavLink: true,
    icon: 'campaign',
  },
  support: {
    name: 'Support',
    path: 'support',
    title: 'Support' + TITLE_SUFFIX,
    breadcrumb: 'Support',
    icon: 'contact_support',
  },
  about: {
    name: 'About',
    path: 'about',
    title: 'About' + TITLE_SUFFIX,
    breadcrumb: 'About',
  },
  error: {
    name: 'Error',
    path: 'error',
    title: 'Error' + TITLE_SUFFIX,
  },
  cases: {
    name: 'Cases',
    path: 'cases',
  },
  scenarios: {
    name: 'Scenarios',
    path: 'scenarios',
    title: 'Scenario | Prosumer',
  },
  prototype: {
    name: 'Prototype',
    path: 'prototype',
    title: 'Prototype' + TITLE_SUFFIX,
    sideNavLink: true,
    breadcrumb: 'Prototype',
    sideNav: true,
    icon: 'wb_incandescent',
  },
  share: {
    name: 'Shared Project',
    path: 'share',
    preload: true,
    title: 'Shared Project',
    sideNav: true,
  },
  userPreferences: {
    name: 'User Preferences',
    path: 'user-preferences',
    title: `User Preferences ${TITLE_SUFFIX}`,
    breadcrumb: 'User Preferences',
    icon: 'settings',
  },
};

const routes: Routes = [
  {
    path: FEATURES.prototype.path,
    loadChildren: () =>
      import('./+prototype/prototype.module').then((m) => m.PrototypeModule),
    data: FEATURES.prototype,
    canActivate: [AuthGuard],
  },
  {
    path: FEATURES.dashboard.path,
    loadChildren: () =>
      import('./+dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: FEATURES.dashboard,
    canActivate: [AuthGuard],
  },
  {
    path: FEATURES.userPreferences.path,
    loadChildren: () =>
      import('./+user-preferences/user-preferences.module').then(
        (m) => m.UserPreferencesModule,
      ),
    data: FEATURES.userPreferences,
    canActivate: [AuthGuard],
  },
  // renewable profile
  {
    path: FEATURES.renewable_profile.path,
    loadChildren: () =>
      import('./+renewableprofile/renewable-profile.module').then(
        (m) => m.RenewableProfileModule,
      ),
    data: FEATURES.renewable_profile,
    canActivate: [AuthGuard],
  },
  {
    path: FEATURES.support.path,
    loadChildren: () =>
      import('./libs/eyes-shared').then((m) => m.EyesSupportModule),
    data: FEATURES.support,
    canActivate: [AuthGuard],
    resolve: [UserInfoResolver],
  },
  {
    path: FEATURES.about.path,
    loadChildren: () =>
      import('./+about/about.module').then((m) => m.AboutModule),
    data: FEATURES.about,
    canActivate: [AuthGuard],
  },
  {
    path: FEATURES.home.path,
    canActivate: [HOME_URL_REDIRECT_PROVIDER],
    component: ErrorPageComponent,
  },
  {
    path: FEATURES.login.path,
    canActivate: [LOGIN_URL_REDIRECT_PROVIDER],
    loadChildren: () => loadErrorModule,
  },
  {
    path: FEATURES.error.path,
    loadChildren: () => loadErrorModule,
    data: FEATURES.error,
    canActivate: [AuthGuard],
    resolve: [UserInfoResolver],
  },
  {
    path: FEATURES.projects.path,
    loadChildren: () =>
      import('./+project/project.module').then((m) => m.ProjectModule),
    data: FEATURES.projects,
    canActivate: [AuthGuard],
  },
  // faq
  {
    path: FEATURES.faq.path,
    canActivate: [FAQ_URL_REDIRECT_PROVIDER],
    loadChildren: () => loadErrorModule,
  },
  // training
  {
    path: FEATURES.training.path,
    canActivate: [TRAINING_URL_REDIRECT_PROVIDER],
    loadChildren: () => loadErrorModule,
  },
  // updates
  {
    path: FEATURES.updates.path,
    canActivate: [UPDATES_URL_REDIRECT_PROVIDER],
    loadChildren: () => loadErrorModule,
  },
  // share
  {
    path: FEATURES.share.path,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./+share/share.module').then((m) => m.ShareModule),
    data: FEATURES.share,
    resolve: { query: SharedLandingPageResolver },
  },
  {
    path: '',
    redirectTo: FEATURES.dashboard.path,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: FEATURES.error.path,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
