import { REDIRECT_URL_KEY } from 'prosumer-app/app.references';
import { AuthService } from 'prosumer-app/core/services/auth';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private location: Location,
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.checkAuth$.pipe(
      mergeMap((loggedIn) => {
        if (loggedIn) {
          return of(true);
        }
        throw new Error('Unauthorized Access');
      }),
      catchError(() => this.handleUnauthorizedAccess()),
    );
  }

  private handleUnauthorizedAccess(): Observable<boolean> {
    this.saveSharedProjectLink();
    this.redirectToLogin();
    return of(false);
  }

  private redirectToLogin() {
    this.router.navigate(['/home']);
  }

  private saveSharedProjectLink(): void {
    const path = this.location.path();
    if (path.includes('/share?')) {
      localStorage.setItem(REDIRECT_URL_KEY, path);
    }
  }
}
