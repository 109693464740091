import { USER_GROUPS } from 'prosumer-app/app.references';
import { SessionExpiredHandleService } from 'prosumer-app/core/services/session-expired-handle';
import { environment } from 'prosumer-env/environment';
import { Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AUTH_CONFIG_TOKEN,
  AuthConfig,
  AuthService as OculusAuthService,
} from '@oculus/auth/amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends OculusAuthService {
  constructor(
    @Inject(AUTH_CONFIG_TOKEN) readonly authConfig: AuthConfig,
    private router: Router,
    private sessionExpiredHandle: SessionExpiredHandleService,
  ) {
    super(authConfig);
  }

  refreshToken(): Observable<any> {
    return super
      .refreshToken()
      .pipe(
        catchError((er) =>
          this.sessionExpiredHandle.shouldDisplayExpiredAccessMessage(er),
        ),
      );
  }

  logout(): void {
    super.logout();
    this.router.navigate(['/home']);
  }

  protected generateUrl(): string {
    const identityProvider = environment.cognito.Auth.oauth.identityProvider;
    const { oauth, userPoolWebClientId } = this.authConfig;
    const { domain, redirectSignIn, responseType } = oauth;
    let url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;
    if (identityProvider) {
      url =
        `https://${domain}/oauth2/authorize?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}` +
        `&identity_provider=${identityProvider}`;
    }
    return url;
  }

  hasT3AAccess() {
    return this.user$.pipe(
      filter((v) => !!v),
      map((userDetails) => {
        const allowedGroups = [USER_GROUPS.SUPER_USER, USER_GROUPS.T3A];
        return (
          !!userDetails.attributes &&
          allowedGroups.some((group) =>
            userDetails.attributes['custom:groupName']?.includes(group),
          )
        );
      }),
    );
  }
}
