import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredMessageComponent } from './components/session-expired-message';
import { Observable, of } from 'rxjs';

const REFRESH_TOKEN_MESSAGE_EXPIRED = 'Refresh Token has expired';
const REFRESH_TOKEN_MESSAGE_REVOKED = 'Refresh Token has been revoked';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiredHandleService {
  get refreshTokenMessages() {
    return [REFRESH_TOKEN_MESSAGE_REVOKED, REFRESH_TOKEN_MESSAGE_EXPIRED];
  }

  private dialogDisplayed = false;

  constructor(private dialog: MatDialog) {}

  shouldDisplayExpiredAccessMessage(err: any = {}): Observable<any> {
    if (this.refreshTokenMessages.includes(err.message)) {
      this.displayDialog();
      this.dialogDisplayed = true;
      return of();
    }
    return of(err);
  }

  private displayDialog(): void {
    if (this.dialogDisplayed) {
      return;
    }
    this.dialog.open(SessionExpiredMessageComponent, {
      disableClose: true,
    });
  }
}
